import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Decimal from 'decimal.js'

const breakpointMixin = css`
  ${props => {
    let styles = ''
  
    const breakpoints = Object.keys(props.theme.breakpoint)
    if (breakpoints.map((breakpoint) => props[breakpoint]).some((value) => Boolean(value))) {
      breakpoints.forEach((breakpoint) => {
        if (props[breakpoint]) {
          const width = new Decimal(100).dividedBy(props[breakpoint]).toString()
          const spacing = (props.elementSpacing && props.theme.spacing[props.elementSpacing]) || 0
          styles = styles.concat(`
            @media ${props.theme.media[breakpoint]} {
              width: calc(${width}% - ${spacing} - ${spacing});
            }
          `)
        }
      })
    }
  
    return styles
  }};
`

console.log('breakpointMixin', breakpointMixin)

const FlexItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${props => (props.elementSpacing && props.theme.spacing[props.elementSpacing]) || 'auto'};
  ${breakpointMixin};
`

FlexItem.propTypes = {
  elementSpacing: PropTypes.oneOf([
    'xs', 'sm', 'md', 'lg', 'xl', 'none',
  ]),
  xs: PropTypes.oneOf([
    1, 2, 3, 4, 6
  ]),
  sm: PropTypes.oneOf([
    1, 2, 3, 4, 6
  ]),
  md: PropTypes.oneOf([
    1, 2, 3, 4, 6
  ]),
  lg: PropTypes.oneOf([
    1, 2, 3, 4, 6
  ]),
  xl: PropTypes.oneOf([
    1, 2, 3, 4, 6
  ]),
}

FlexItem.defaultProps = {
  elementSpacing: 'xs',
  xs: null,
  sm: null,
  md: null,
  lg: null,
  xl: null,
}

export default FlexItem
