import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import RegisterForm from '../components/Forms/RegisterForm'
import SimpleLayout from '../layouts/SimpleLayout'
import { useAuth } from '../contexts/AuthProvider'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  @media (min-width: ${props => props.theme.breakpoint.lg}) {
    width: 40vw;
  }
  @media (max-width: ${props => props.theme.breakpoint.md}) {
    width: 80vw;
  }
  @media (max-width: ${props => props.theme.breakpoint.sm}) {
    width: 95vw;
  }
`

const RegisterPage = () => {
  const [loading, setLoading] = React.useState(true)
  const { isLoggedIn, isHydrated } = useAuth()

  React.useEffect(() => {
    if (isHydrated && isLoggedIn) {
      navigate('/')
    } else if (isHydrated && !isLoggedIn) {
      setLoading(false)
    }
  }, [isLoggedIn, isHydrated])

  let children = null
  if (!loading) {
    children = <RegisterForm />
  }

  return (
    <SimpleLayout vCenter={ true }>
      <Container>
        { children }
      </Container>
    </SimpleLayout>
  )
}

export default RegisterPage
