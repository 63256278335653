import { rem } from 'polished'

const theme = {}

theme.color = {
  offBlack: '#101010',
  offBlue: '#a10000',
  white: '#fff',
  black: '#000',
}

theme.brands = {
  twitter: '#1da1f2',
  linkedIn: '#0077b5',
  github: '#6e5494',
}

theme.spacing = {
  xs: rem('8px'),
  sm: rem('10px'),
  md: rem('20px'),
  lg: rem('40px'),
  xl: rem('80px'),
}

theme.size = theme.spacing

theme.breakpoint = {
  xs: rem('0px'),
  sm: rem('600px'),
  md: rem('960px'),
  lg: rem('1280px'),
  xl: rem('1920px'),
}

theme.media = {
  xs: `(min-width: ${theme.breakpoint.xs})`,
  sm: `(min-width: ${theme.breakpoint.sm})`,
  md: `(min-width: ${theme.breakpoint.md})`,
  lg: `(min-width: ${theme.breakpoint.lg})`,
  xl: `(min-width: ${theme.breakpoint.xl})`,
}

export default theme
