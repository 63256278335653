import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import HeaderBar from '../components/HeaderBar'
import FooterBar from '../components/FooterBar'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
// import { useStaticQuery, graphql } from 'gatsby'

config.autoAddCss = false

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: foco, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  h1, h2, h3, h4, h5 {
    font-family: quasimoda, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  p {
    font-size: 1.1rem;
  }
  a {
    text-decoration: none;
    color: #101010;
    &:hover {
      text-decoration: underline;
    }
  }
  .wf-loading {
    opacity: 0.0;
  }
  .wf-active {
    -webkit-transition:  opacity 0.25s ease-in;
    transition:          opacity 0.25s ease-in;
                         opacity: 1;
  }
  .wf-inactive {
    opacity: 1;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const StyledMain = styled.main`
  flex: 2;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  ${props => {
    const { vCenter, theme } = props
    if (vCenter) {
      return `
        @media (min-width: ${theme.breakpoint.sm}) {
          justify-content: center;
          padding-bottom: 121px;
        }
      `;
    }
  }};
`

const SimpleLayout = (props) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <Container>
      <GlobalStyle />
      <HeaderBar />
      <StyledMain vCenter={props.vCenter}>
        {props.children}
      </StyledMain>
      <FooterBar />
    </Container>
  )
}

SimpleLayout.propTypes = {
  children: PropTypes.node.isRequired,
  vCenter: PropTypes.bool,
}

SimpleLayout.defaultProps = {
  vCenter: false,
}

export default SimpleLayout
