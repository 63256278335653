import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MaterialButton from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`

const Children = styled.span`
  visibility: ${props => props.loading ? 'hidden' : 'visible'}; 
`

const StyledProgress = styled(CircularProgress)`
  && {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`

const StyledMaterialButton = styled(MaterialButton)`
  && {
    width: 100%;
    font-family: quasimoda, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    padding: 0.4rem 0.8rem;
    color: ${ props => props.dark ? '#fff' : '#101010' };
    background: ${ props => props.dark ? '#1d1d1d' : 'none' };
    &:hover {
      background: ${ props => props.dark ? '#000' : 'rgba(16, 16, 16, 0.1)' };
    }
  }
`

StyledMaterialButton.propTypes = {
  dark: PropTypes.bool,
}

StyledMaterialButton.defaultProps = {
  dark: false,
}

const TextButton = (props) => {
  return (
    <Wrapper>
      <StyledMaterialButton {...props} disabled={props.loading}>
        {props.loading && <StyledProgress size={24} thickness={8} />}
        <Children loading={props.loading}>{props.children}</Children>
      </StyledMaterialButton>
    </Wrapper>
  )
}

TextButton.propTypes = {
  dark: PropTypes.bool,
  loading: PropTypes.bool,
}

TextButton.defaultProps = {
  dark: false,
  loading: false,
}

export default TextButton
