import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import LogoLink from './LogoLink'
import AuthSection from './AuthSection'

const FlexHeader = styled.header`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: ${props => props.theme.spacing.lg};
  height: ${rem('41px')};
  @media (max-width: ${props => props.theme.breakpoint.sm}) {
    padding: ${props => props.theme.spacing.md};
  }
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: ${props => props.align === 'right' ? 'flex-end' : 'flex-start'};
  justify-content: center;
`

FlexColumn.defaultProps = {
  align: 'left',
}

FlexColumn.propTypes = {
  align: PropTypes.oneOf([
    'left',
    'right',
  ]),
}

const AuthColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${props => props.theme.spacing.md};
  justify-content: center;
  > button {
    font-size: 1rem;
  }
`

const HeaderBar = () => {
  return (
    <FlexHeader>
      <FlexColumn>
        <LogoLink />
      </FlexColumn>
      <AuthColumn>
        <AuthSection />
      </AuthColumn>
    </FlexHeader>
  )
}

export default HeaderBar
