import React from 'react'
import { navigate } from 'gatsby-link'
import { Router } from '@reach/router'
import Home from '../admin/pages/home'
import Settings from '../admin/pages/settings'
import UserManagement from '../admin/pages/userManagement'
import { PageProvider } from '../contexts/PageProvider'
import { useAuth } from '../contexts'

const Admin = () => {
  const [loading, setLoading] = React.useState(true)
  const { isLoggedIn, isHydrated, userData } = useAuth()

  React.useEffect(() => {
    if (userData.type !== 'ADMIN') {
      navigate('/')
    }

    if (isHydrated && !isLoggedIn) {
      navigate('/')
    } else if (isHydrated && isLoggedIn) {
      setLoading(false)
    }
  }, [isLoggedIn, isHydrated, userData])

  if (loading || !isLoggedIn || !isHydrated) {
    return null
  }

  return (
    <PageProvider>
      {!loading && (
        <Router basepath="/admin">
          <UserManagement path="/users" />
          <Settings path="/settings" />
          <Home path="/" />
        </Router>
      )}
    </PageProvider>
  )
}

export default Admin
