import React from 'react'
import { Helmet } from 'react-helmet'

const PageContext = React.createContext({
  setPageTitle: () => {},
})

const PageProvider = (props) => {
  const [title, setTitle] = React.useState('Dashboard')

  const setPageTitle = (title) => {
    setTitle(title)
  }

  const { children, ...restProps } = props

  const pageTitle = title === 'Dashboard' ? title : `${title} - Dashboard`

  return (
    <PageContext.Provider value={{
      setPageTitle,
      title,
    }} {...restProps}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </PageContext.Provider>
  )
}

const usePage = () => React.useContext(PageContext)

const PageConsumer = PageContext.Consumer

export {
  PageProvider,
  PageConsumer,
  usePage,
}
