import PropTypes from 'prop-types'
import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  flex-wrap: wrap;
  width: 100%;
  margin: ${props => (props.elementSpacing && props.theme.spacing[props.elementSpacing]) || 'auto'};
`

Flex.propTypes = {
  elementSpacing: PropTypes.oneOf([
    'xs', 'sm', 'md', 'lg', 'xl', 'none',
  ]),
  reverse: PropTypes.bool,
}

Flex.defaultProps = {
  elementSpacing: 'none',
  reverse: false,
}

export default Flex
