import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import SimpleLayout from '../layouts/SimpleLayout'
import SEO from '../components/SEO'
import { downloads } from '../data/mainData'

const ContainedSection = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`

ContainedSection.defaultProps = {
  align: 'left',
}

ContainedSection.propTypes = {
  align: PropTypes.oneOf([
    'left',
    'right',
  ]),
}

const Section = styled.div`
  margin: ${props => props.theme.spacing.xl};
  font-family: 'Sen', sans-serif;
  font-size: ${rem('25px')};
  color: ${props => props.theme.color.offBlack};
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  text-align: ${props => props.align};
  &> div {
    flex: 1;
  }
  ${props => {
    const { breakpoint, spacing } = props.theme
    return `
      @media (min-width: ${breakpoint.md}) {
        max-width: 60vw;
      }
      @media (max-width: ${breakpoint.sm}) {
        margin: ${spacing.md};
      }
    `
  }};
`

const NavLink = styled.a`
  font-weight: 700;
  color: ${props => props.theme.color.offBlue};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Paragraph = styled.div`
  font-family: 'Sen', sans-serif;
  font-size: 1.5625rem;
  line-height: ${rem('40px')};
  text-align: ${props => props.align || 'left'};
  padding-bottom: ${rem('20px')};
`

const IndexPage = () => {
  return (
    <SimpleLayout vCenter={true}>
      <SEO title="Home" />
      <ContainedSection>
        <Section>
          <div>
            <Paragraph>
              Hey! I am a self-motivated Software Engineer with a passion for understanding and always learning.
            </Paragraph>
            <Paragraph>
              <NavLink href={downloads.resume.url} target="_blank">Download Resume</NavLink>
            </Paragraph>
          </div>
        </Section>
      </ContainedSection>
    </SimpleLayout>
  )
}

export default IndexPage
