import React from 'react'
import api from '../lib/api'
import { useSnackbar } from './SnackbarProvider'

const AuthContext = React.createContext({
  userData: null,
  isLoggedIn: false,
  isLoggingIn: false,
  isHydrated: false,
  login: async () => {},
  logout: async () => {},
  retrieveUserData: async () => {},
})

const AuthProvider = (props) => {
  const [isLoggingIn, setIsLoggingIn] = React.useState(false)
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [userData, setUserData] = React.useState(null)
  const [isHydrated, setHydrated] = React.useState(false)
  const { openSnackbar } = useSnackbar()

  const login = async (email, password) => {
    setIsLoggingIn(true)
    try {
      localStorage.removeItem('token')
      const response = await api.post('/rest/account/login', {
        email,
        password: btoa(password),
      })
      localStorage.setItem('token', response.data.accessToken)
      localStorage.setItem('refreshToken', response.data.refreshToken)
      setUserData(response.data.userData)
      setIsLoggedIn(true)
      setIsLoggingIn(false)
      return true
    } catch (err) {
      await logout()
      if (err.response.data.error.code === 'INVALID_PASSWORD') {
        throw new Error('Invalid password, please try again.')
      }
      throw new Error('Something went wrong. Please try again later.')
    }
  }

  const logout = async () => {
    try {
      await api.post('/rest/account/logout', {
        refreshToken: btoa(localStorage.getItem('refreshToken')),
      })
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('token')
      setIsLoggedIn(false)
      setIsLoggingIn(false)
      setUserData(null)
      return true
    } catch (err) {
      openSnackbar({
        message: 'Error logging out!',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return false
    }
  }

  const retrieveUserData = async () => {
    try {
      const response = await api.get('/rest/account')
      setUserData(response.data)
      return response.data
    } catch (err) {
      await logout()
    }
  }

  React.useEffect(() => {
    const token = localStorage.getItem('token')
    if (token && !userData) {
      retrieveUserData()
        .then(() => {
          setIsLoggedIn(true)
        })
        .finally(() => {
          setHydrated(true)
        })
    } else if (!token) {
      localStorage.removeItem('token')
      setIsLoggedIn(false)
      setUserData(null)
      setHydrated(true)
    }
  }, [userData])

  return (
    <AuthContext.Provider value={{
      userData,
      isLoggedIn,
      isLoggingIn,
      isHydrated,
      login,
      logout,
      retrieveUserData,
    }} {...props} />
  )
}

const useAuth = () => React.useContext(AuthContext)

const AuthConsumer = AuthContext.Consumer

export {
  AuthProvider,
  AuthConsumer,
  useAuth,
}
