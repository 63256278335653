import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const SnackbarContext = React.createContext({
  openSnackbar: () => {},
  closeSnackbar: () => {},
})

const defaultConfig = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  autoHideDuration: 6000,
  message: 'Test',
  action: {
    onClick: () => {},
    content: null,
  },
}

const SnackbarProvider = (props) => {
  const [isOpen, setOpen] = React.useState(false)
  const [config, setConfig] = React.useState(defaultConfig)
  
  const openSnackbar = (config = {}) => {
    setConfig(Object.assign({}, defaultConfig, config))
    setOpen(true)
  }
  
  const closeSnackbar = () => {
    setOpen(false)
  }

  const handleExited = () => {
    setConfig(defaultConfig)
  }
  
  const { children, ...rest } = props
  
  return (
    <SnackbarContext.Provider value={{
      openSnackbar,
      closeSnackbar,
    }} {...rest}>
      {children}
      <Snackbar
        anchorOrigin={config.anchorOrigin}
        open={isOpen}
        color={"#ac0000"}
        autoHideDuration={config.autoHideDuration}
        onClose={closeSnackbar}
        onExited={handleExited}
        message={config.message}
        action={(
          <>
            {config.action.content && (
              <Button color="secondary" size="small" onClick={config.action.onClick}>
                {config.action.content}
              </Button>
            )}
            <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      />
    </SnackbarContext.Provider>
  )
}

const useSnackbar = () => React.useContext(SnackbarContext)

const SnackbarConsumer = SnackbarContext.Consumer

export {
  SnackbarProvider,
  SnackbarConsumer,
  useSnackbar,
}
