import React from 'react'
import { navigate } from 'gatsby-link'
import styled from 'styled-components'
import { useFeature } from '@optimizely/react-sdk'
import Tooltip from '@material-ui/core/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut, faTachometer } from '@fortawesome/pro-duotone-svg-icons'
import { useAuth } from '../../contexts'
import { TextButton } from '../Button'

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: ${props => props.theme.spacing.md};
  margin-right: ${props => props.theme.spacing.md};
  &:hover {
    cursor: pointer;
  }
`

const RefHolder = styled.span`
  padding: 0;
  margin: 0;
  box-sizing: inherit;
`

const handleLogin = (event) => {
  event.preventDefault()
  return navigate('/login')
}

const goToDashboard = (event) => {
  event.preventDefault()
  return navigate('/dashboard')
}

const AuthSection = () => {
  const { isLoggedIn, logout, isHydrated } = useAuth()
  const [dashboardEnabled] = useFeature('dashboard', {
    autoUpdate: true,
  })

  if (!isHydrated) {
    return null
  }

  const render = []

  if (isHydrated && isLoggedIn) {
    if (dashboardEnabled) {
      render.push((
        <Tooltip title="Dashboard" placement="top" key={render.length + 1}>
          <RefHolder>
            <StyledIcon size="2x" icon={faTachometer} onClick={goToDashboard} />
          </RefHolder>
        </Tooltip>
      ))
    }
    render.push((
      <Tooltip title="Logout" placement="top" key={render.length + 1}>
        <RefHolder>
          <StyledIcon size="2x" icon={faSignOut} onClick={logout} />
        </RefHolder>
      </Tooltip>
    ))
  } else if (isHydrated && !isLoggedIn) {
    render.push((
      <TextButton onClick={handleLogin} dark key={render.length + 1}>Login</TextButton>
    ))
  }

  return (
    <span>
      {render}
    </span>
  )
}

export default AuthSection
