import React from 'react'
import { Tabs } from 'antd'
import MainLayout from '../layouts/MainLayout'
import { usePage } from '../../contexts/PageProvider'
import UsersTab from '../components/UserManagement/UsersTab'
import OrganizationsTab from '../components/UserManagement/OrganizationsTab'
import api from '../../lib/api'

const getUsers = () => {
  return api.get('/rest/admin/users')
    .then((response) => {
      return {
        users: response.data.users.map((user, key) => {
          return { ...user, key }
        }),
        total: response.data.total,
      }
    })
}

const UserManagement = () => {
  const [ users, setUsers ] = React.useState([])
  const [ totalUsers, setTotalUsers ] = React.useState(0)
  const [ usersLoading, setUsersLoading ] = React.useState(true)
  const { setPageTitle } = usePage()

  const retrieveUsers = () => {
    getUsers().then((data) => {
      setUsers(data.users)
      setTotalUsers(data.total)
      setUsersLoading(false)
    })
  }

  React.useEffect(() => {
    setPageTitle('User Management')
  }, [setPageTitle])

  React.useEffect(() => {
    retrieveUsers()
  }, [])

  const onTabChange = (key) => {
    switch (key) {
      case 'users':
        setUsersLoading(true)
        retrieveUsers()
        break
      case 'organizations':
        break
      default:
        break
    }
  }

  return (
    <MainLayout>
      <Tabs defaultActiveKey="users" animated={false} onChange={onTabChange}>
        <Tabs.TabPane tab="Users" key="users">
          <UsersTab users={users} totalUsers={totalUsers} loading={usersLoading} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Organizations" key="organizations">
          <OrganizationsTab />
        </Tabs.TabPane>
      </Tabs>
    </MainLayout>
  )
}

export default UserManagement
