import React from 'react'
import { useForm } from 'react-form'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { useAuth } from '../../../contexts/AuthProvider'
import { FlexItem, Flex } from '../../Flex'
import TextField from '../../TextField'
import { Button, TextButton } from '../../Button'
import { validateEmail } from '../../../lib/validation'

const Container = styled.div`
  width: 95vw;
  @media (min-width: ${props => props.theme.breakpoint.sm}) {
    width: 90vw;
  }
  @media (min-width: ${props => props.theme.breakpoint.md}) {
    width: 70vw;
  }
  @media (min-width: ${props => props.theme.breakpoint.lg}) {
    width: 55vw;
  }
  @media (min-width: ${props => props.theme.breakpoint.xl}) {
    width: 35vw;
  }
`

const Header = styled.h2`
  width: 100%;
  margin-left: ${props => props.theme.spacing.xs};
`

const ErrorState = styled.p`
  color: #a10000;
  width: 100%;
  font-size: 1.1rem;
  text-align: center;
`

const handleCreateAccount = (event) => {
  event.preventDefault()
  return navigate('/register')
}

const validateEmailAddress = async (email, instance) => {
  return instance.debounce(async () => {
    if (!validateEmail(email)) {
      return 'Invalid e-mail address'
    }
    return false
  }, 500)
}

const validatePassword = async (password, instance) => {
  return instance.debounce(async () => {
    if (password.length === 0) {
      return 'Password is required'
    }
    return false
  })
}

const LoginForm = () => {
  const [errorState, setErrorState] = React.useState(null)
  const { login } = useAuth()

  const { Form, meta } = useForm({
    defaultValues: React.useMemo(() => ({
      email: '',
      password: '',
    }), []),
    onSubmit: async (values, instance) => {
      return instance.debounce(async () => {
        try {
          await login(values.email, values.password)
          await navigate('/')
        } catch (err) {
          setErrorState(err.message)
        }
      }, 500)
    }
  })

  return (
    <Container>
      <Header>Log into your account</Header>
      <Form>
        <Flex>
          <FlexItem>
            <TextField
              field="email"
              name="email"
              label="E-Mail Address"
              validate={validateEmailAddress}
            />
          </FlexItem>
          <FlexItem>
            <TextField
              field="password"
              name="password"
              label="Password"
              type="password"
              validate={validatePassword}
            />
          </FlexItem>
        </Flex>
        <Flex reverse>
          <FlexItem xs={1} sm={3}>
            <TextButton loading={meta.isSubmitting} dark type="submit" disabled={meta.isSubmitting && meta.canSubmit}>Login</TextButton>
          </FlexItem>
          <FlexItem xs={1} sm={3}>
            <TextButton>
              Forgot Password?
            </TextButton>
          </FlexItem>
          <FlexItem xs={1} sm={3}>
            <Button onClick={handleCreateAccount}>
              Create Account
            </Button>
          </FlexItem>
          <FlexItem>
            <ErrorState>{errorState}</ErrorState>
          </FlexItem>
        </Flex>
      </Form>
    </Container>
  )
}

export default LoginForm
