import React from 'react'
import { navigate } from 'gatsby-link'
import styled from 'styled-components'
import SimpleLayout from '../layouts/SimpleLayout'
import LoginForm from '../components/Forms/LoginForm'
import { useAuth } from '../contexts/AuthProvider'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`

const LoginPage = () => {
  const [loading, setLoading] = React.useState(true)
  const { isLoggedIn, isHydrated } = useAuth()

  React.useEffect(() => {
    if (isHydrated && isLoggedIn) {
      navigate('/')
    } else if (isHydrated && !isLoggedIn) {
      setLoading(false)
    }
  }, [isLoggedIn, isHydrated])

  let children = null
  if (!loading) {
    children = <LoginForm />
  }

  return (
    <SimpleLayout vCenter={ true }>
      <Container>
        {children}
      </Container>
    </SimpleLayout>
  )
}

export default LoginPage
