import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

const SEO = (props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  const metaDescription = props.description || data.site.siteMetadata.description

  const helmetProps = {
    htmlAttributes: {
      lang: props.lang,
    },
    title: props.title,
    titleTemplate: `%s | ${data.site.siteMetadata.title}`,
    meta: [
      { name: 'description', content: metaDescription },
      { property: 'og:title', content: props.title },
      { property: 'og:description', content: metaDescription },
      { property: 'og:type', content: 'website' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:creator', content: data.site.siteMetadata.author },
      { name: 'twitter:title', content: props.title },
      { name: 'twitter:description', content: metaDescription }
    ].concat(props.meta),
  }

  return (
    <Helmet {...helmetProps} />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: 'Hey! I am a self-motivated Software Engineer with a passion for understanding and always learning.',
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
