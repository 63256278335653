import React from 'react'
import styled from 'styled-components'
import MaterialButton from '@material-ui/core/Button'

const StyledMaterialButton = styled(MaterialButton)`
  && {
    font-family: quasimoda, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    padding: 0.4rem 0.8rem;
    border-color: #101010;
    color: #101010;
    &:hover {
      background: rgba(16,16,16,0.1);
    }
  }
`

const Button = (props) => {
  return (
    <StyledMaterialButton variant="outlined" disableElevation {...props}>
      {props.children}
    </StyledMaterialButton>
  )
}

export default Button
