import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Modal from '@material-ui/core/Modal'

const StyledModal = styled.div`
  position: absolute;
  width: ${rem('400px')};
  background-color: #101010;
  border: 2px solid #000;
  padding: ${props => props.theme.spacing.sm};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: ${props => props.theme.size.xs};
  color: #fff;
`

const ModalContext = React.createContext({
  openModal: () => {},
  closeModal: () => {},
})

const ModalProvider = (props) => {
  const [isOpen, setOpen] = React.useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const { children, ...rest } = props

  return (
    <ModalContext.Provider value={{
      openModal,
      closeModal,
    }} {...rest}>
      {children}
      <Modal
        aria-labelledby="modal"
        aria-describedby="modal-description"
        open={isOpen}
        onClose={closeModal}
      >
        <StyledModal>Test</StyledModal>
      </Modal>
    </ModalContext.Provider>
  )
}

const useModal = () => React.useContext(ModalContext)

const ModalConsumer = ModalContext.Consumer

export {
  ModalProvider,
  ModalConsumer,
  useModal,
}
