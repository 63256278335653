import React from 'react'
import { navigate } from 'gatsby-link'
import { Router } from '@reach/router'
import { useFeature } from '@optimizely/react-sdk'
import Home from '../dashboard/pages/home'
import { PageProvider } from '../contexts/PageProvider'
import { useAuth } from '../contexts'

const Dashboard = () => {
  const [loading, setLoading] = React.useState(true)
  const { isLoggedIn, isHydrated } = useAuth()
  const [isEnabled, _, clientReady] = useFeature('dashboard', {
    autoUpdate: true,
  })

  React.useEffect(() => {
    if (clientReady && !isEnabled) {
      navigate('/')
    }

    if (isHydrated && !isLoggedIn) {
      navigate('/')
    } else if (isHydrated && isLoggedIn) {
      setLoading(false)
    }
  }, [isLoggedIn, isHydrated, clientReady, isEnabled])

  if (loading || !isLoggedIn || !isHydrated) {
    return null
  }

  return (
    <PageProvider>
      {isEnabled && (
        <Router basepath="/dashboard">
          <Home path="/" />
        </Router>
      )}
    </PageProvider>
  )
}

export default Dashboard
