import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

const SectionTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`

const StyledLink = styled.span`
  font-weight: normal;
  margin-top: ${props => props.theme.spacing.sm};
  ${props => {
    if (props.disabled) {
      return `
        font-style: italic;
        font-weight: normal;
        color: darkgray;
        margin-top: ${props => props.theme.spacing.sm};
      `
    }  
  }};
`

const LinkSection = (props) => {
  const sectionLinks = props.links.map((link, key) => {
    const isUrl = link.url.startsWith('http')
    return (
      <StyledLink disabled={link.comingSoon} key={key}>
        {(link.comingSoon && (
          <span>{link.title}</span>
        )) || (isUrl ? (
          <a href={link.url} target="_blank" rel="noopener noreferrer">{link.title}</a>
        ) : (
          <Link to={link.url}>{link.title}</Link>
        ))}
      </StyledLink>
    )
  })

  return (
    <>
      <SectionTitle>{props.title}</SectionTitle>
      {sectionLinks}
    </>
  )
}

LinkSection.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    comingSoon: PropTypes.bool,
  })).isRequired,
}

export default LinkSection
