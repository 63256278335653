import React from 'react'
import { usePage } from '../../../../contexts/PageProvider'

const OrganizationsTab = () => {
  const { setPageTitle } = usePage()
  const [test, setTest] = React.useState(null)

  React.useEffect(() => {
    setPageTitle('User Management | Organizations')
    setTimeout(() => {
      setTest('test')
    }, 2000)
  }, [setPageTitle])

  return (
    <div>
      Organizations
      {test}
    </div>
  )
}

export default OrganizationsTab
