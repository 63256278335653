import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import styled from 'styled-components'
import { socialMedia } from '../../data/mainData'

const SocialLink = styled.a`
  margin-left: ${props => props.theme.spacing.xs};
  @media (min-width: ${props => props.theme.breakpoint.sm}) {
    margin-left: ${props => props.theme.spacing.md};
  }
  color: ${props => props.theme.color.offBlack};
  &:hover {
    text-decoration: none;
  }
  &:focus {
    color: ${props => props.theme.color.offBlack}
  }
`

const SocialIcon = styled(FontAwesomeIcon).attrs(() => ({
  size: '2x',
}))`
  color: ${props => props.theme.brands[props.hoverbrand]};
  &:hover {
    cursor: pointer;
  }
`

SocialIcon.propTypes = {
  hoverbrand: PropTypes.string.isRequired,
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  text-align: right;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
`

const SocialMediaSection = () => {
  return (
    <Container>
      <SocialLink href={socialMedia.github.url} target="_blank">
        <SocialIcon icon={faGithub} hoverbrand="github" />
      </SocialLink>
      <SocialLink href={socialMedia.linkedIn.url} target="_blank">
        <SocialIcon icon={faLinkedin} hoverbrand="linkedIn" />
      </SocialLink>
      <SocialLink href={socialMedia.twitter.url} target="_blank">
        <SocialIcon icon={faTwitter} hoverbrand="twitter" />
      </SocialLink>
    </Container>
  )
}

export default SocialMediaSection
