import React from 'react'
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'
import { useAuth } from './AuthProvider'

const optimizely = createInstance({
  sdkKey: process.env.GATSBY_OPTIMIZELY_KEY,
})

const CustomOptimizelyProvider = (props) => {
  const { userData, isHydrated } = useAuth()

  if (isHydrated) {
    return (
      <OptimizelyProvider
        optimizely={optimizely}
        timeout={500}
        user={{
          id: userData ? userData.id : undefined,
          attributes: {
            id: userData ? userData.id : undefined,
          },
        }}
      >
        {props.children}
      </OptimizelyProvider>
    )
  }

  return null
}

export default CustomOptimizelyProvider
