export const socialMedia = {
  twitter: {
    url: 'https://twitter.com/Vinlockz',
  },
  linkedIn: {
    url: 'https://linkedin.com/in/dwashbrook',
  },
  github: {
    url: 'https://github.com/Vinlock',
  },
}

export const downloads = {
  resume: {
    url: 'http://cdn.dak.dev/Dak_Washbrook_Resume.pdf',
  },
}

export const footerSections = [
  {
    title: 'Me',
    links: [
      {
        title: 'Resume',
        url: downloads.resume.url,
      },
      {
        title: 'About Dak',
        url: '/about',
        comingSoon: true,
      },
    ],
  },
  {
    title: 'Links',
    links: [
      {
        title: 'Privacy Policy',
        url: '/privacy',
      },
      {
        title: 'Terms & Conditions',
        url: '/terms',
      },
      {
        title: 'Cookie Policy',
        url: '/cookies',
      },
    ]
  },
]
