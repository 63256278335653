import React from 'react'
import styled from 'styled-components'
import { Flex, FlexItem } from '../Flex'
import LinkSection from './LinkSection'
import SocialMediaSection from './SocialMediaSection'
import { footerSections } from '../../data/mainData'

const StyledFooter = styled.footer`
  flex-shrink: 0;
  padding: ${props => props.theme.spacing.lg};
`

const Container = styled.div`
  width: 100%;
  margin: auto;
  @media ${props => props.theme.media.md} {
    width: 65%;
  }
`

const FooterBar = () => {
  const sections = footerSections.map((section, key) => {
    return (
      <FlexItem xs={1} sm={footerSections.length + 1} key={key}>
        <LinkSection title={section.title} links={section.links} />
      </FlexItem>
    )
  })

  return (
    <StyledFooter>
      <Container>
        <Flex>
          {sections}
          <FlexItem xs={1} sm={footerSections.length + 1}>
            <SocialMediaSection />
          </FlexItem>
        </Flex>
      </Container>
    </StyledFooter>
  )
}

export default FooterBar
