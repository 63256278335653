import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'polished'
import Logo from '../../images/logo.inline.svg'

const StyledLogo = styled(Logo)`
  width: ${rem('80px')};
  fill: #101010;
`

const LogoLink = () => {
  return (
    <Link to="/">
      <StyledLogo />
    </Link>
  )
}

export default LogoLink
