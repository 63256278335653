import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SimpleLayout from '../layouts/SimpleLayout'
import SEO from '../components/SEO'

const Container = styled.div`
  margin: ${props => props.theme.spacing.lg};
`

const MarkdownPageLayout = (props) => {
  return (
    <SimpleLayout>
      <SEO title={props.data.markdownRemark.frontmatter.title} />
      <Container dangerouslySetInnerHTML={{
        __html: props.data.markdownRemark.html,
      }} />
    </SimpleLayout>
  )
}

export default MarkdownPageLayout

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`
