import React from 'react'
import { usePage } from '../../contexts/PageProvider'
import MainLayout from '../layouts/MainLayout'

const Home = () => {
  const { setPageTitle } = usePage()

  React.useEffect(() => {
    setPageTitle('Home')
  }, [setPageTitle])

  return (
    <MainLayout>
      <p>Home</p>
    </MainLayout>
  )
}

export default Home
