/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { ThemeProvider } from 'styled-components'
import { StylesProvider } from '@material-ui/core/styles'
import { SnackbarProvider, ModalProvider, AuthProvider, OptimizelyProvider } from './src/contexts'
import theme from './src/theme'

export const wrapRootElement = ({ element }) => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <ModalProvider>
            <AuthProvider>
              <OptimizelyProvider>
                {element}
              </OptimizelyProvider>
            </AuthProvider>
          </ModalProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}
