import React from 'react'
import styled from 'styled-components'
import { Table } from 'antd'
import { usePage } from '../../../../contexts/PageProvider'
import UserExpand from './UserExpand'

const Summary = styled.div`
  text-align: right;
`

const tableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (_, record) => {
      return `${record.firstName} ${record.lastName}`
    },
    width: '15%',
  },
  {
    title: 'E-Mail',
    dataIndex: 'email',
    width: '15%',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    align: 'center',
    filters: [
      { text: 'Admin', value: 'ADMIN' },
      { text: 'Manager', value: 'MANAGER' },
      { text: 'Member', value: 'MEMBER' },
    ],
    onFilter: (value, record) => record.type.indexOf(value) === 0,
    width: '15%',
    render: (value) => {
      switch (value) {
        case 'ADMIN':
          return 'Admin'
        case 'MANAGER':
          return 'Manager'
        case 'MEMBER':
          return 'Member'
        default:
          return 'Invalid Type'
      }
    }
  },
  {
    title: 'ID',
    dataIndex: 'id',
    render: (value) => {
      return (
        <code>{value}</code>
      )
    }
  }
]

const UsersTab = (props) => {
  const { setPageTitle } = usePage()

  React.useEffect(() => {
    setPageTitle('User Management | Users')
  }, [setPageTitle])

  return (
    <div>
      <Table
        columns={tableColumns}
        dataSource={props.users}
        loading={props.loading}
        size="small"
        pagination={{
          hideOnSinglePage: true,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <UserExpand user={record} />
          ),
          rowExpandable: () => true,
        }}
        title={() => (
          <Summary>
            <b>Total Users:</b> {props.totalUsers}
          </Summary>
        )}
      />
    </div>
  )
}

export default UsersTab
