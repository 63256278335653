import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-form'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import TextField from '../../TextField'
import { Button, TextButton } from '../../Button'
import { FlexItem, Flex } from '../../Flex'
import api from '../../../lib/api'

const Container = styled.div`
  width: 100%;
`

const Header = styled.h2`
  width: 100%;
  margin-left: ${props => props.theme.spacing.xs};
`

const ErrorState = styled.p`
  color: #a10000;
  width: 100%;
  font-size: 1.1rem;
  text-align: center;
`

const Center = styled.div`
  text-align: center;
`

const handleProceedToLogin = (event) => {
  event.preventDefault()
  return navigate('/login')
}

const handleCancelRegistration = (event) => {
  event.preventDefault()
  return navigate('/')
}

const RegisterForm = () => {
  const [errorState, setErrorState] = React.useState(null)
  const [complete, setComplete] = React.useState(false)
  const [userEmailAddress, setEmailAddress] = React.useState(null)

  const {Form, meta} = useForm({
    defaultValues: React.useMemo(
      () => ({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
      }),
      []),
    onSubmit: async (values, instance) => {
      return instance.debounce(async () => {
        try {
          await api.post('/rest/account/register', {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: btoa(values.password),
          })
          setEmailAddress(values.email)
          setComplete(true)
        } catch (err) {
          if (err.response.data.error && err.response.data.error.code === 'USER_EXISTS') {
            setErrorState('E-Mail is in use. Did you forget your password?')
          } else {
            setErrorState('Something went wrong. Please try again later.')
          }
        }
      }, 500)
    }
  })

  if (complete) {
    const endIcon = <FontAwesomeIcon icon={faChevronRight} />

    return (
      <Container>
        <Center>
          <h2>Verification Required</h2>
          <p>You have successfully registered for an account.</p>
          <p>Please check your e-mail <b>{userEmailAddress}</b> to complete verification and activate your account.</p>
          <p>
            <Button onClick={handleProceedToLogin} endIcon={endIcon}>
              Proceed to Login
            </Button>
          </p>
        </Center>
      </Container>
    )
  } else {
    return (
      <Container>
        <Header>Create your account</Header>
        <Form>
          <Flex>
            <FlexItem xs={1} sm={2}>
              <TextField field="firstName" name="firstName" label="First Name" />
            </FlexItem>
            <FlexItem xs={1} sm={2}>
              <TextField field="lastName" name="lastName" label="Last Name" />
            </FlexItem>
            <FlexItem xs={1}>
              <TextField field="email" name="email" label="E-Mail Address" />
            </FlexItem>
            <FlexItem xs={1}>
              <TextField
                field="password"
                name="password"
                label="Password"
                type="password"
                autoComplete="new-password"
              />
            </FlexItem>
            <FlexItem xs={1}>
              <TextField
                field="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                type="password"
              />
            </FlexItem>
            <FlexItem xs={2}>
              <TextButton onClick={handleCancelRegistration} disabled={ meta.isSubmitting }>Cancel Registration</TextButton>
            </FlexItem>
            <FlexItem xs={2}>
              <Button type="submit" disabled={ meta.isSubmitting && meta.canSubmit }>Next</Button>
            </FlexItem>
            <FlexItem xs={1}>
              <ErrorState>{ errorState }</ErrorState>
            </FlexItem>
          </Flex>
        </Form>
      </Container>
    )
  }
}

export default RegisterForm
