import axios from 'axios'
import { navigate } from 'gatsby-link'

const { GATSBY_API_URL } = process.env

const createAxiosInstance = () => {
  const options = {
    baseURL: GATSBY_API_URL,
    timeout: 5000,
    headers: {},
  }

  return axios.create(options)
}

const api = () => {
  const instance = createAxiosInstance()

  instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = `Bearer ${token}`
    return config
  }, (error) => {
    return Promise.reject(error)
  })

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const refreshToken = localStorage.getItem('refreshToken')

      if (!refreshToken) {
        localStorage.removeitem('token')
        localStorage.removeitem('refreshToken')
        navigate('/login')
        return Promise.reject(error)
      }

      const originalRequest = error.config
      if (error.response.status === 401) {
        if (error.response.data?.error?.code === 'EXPIRED_TOKEN' && !originalRequest._retry) {
          if (originalRequest.url.endsWith('/account/refresh')) {
            navigate('/login')
            return Promise.reject(error)
          }

          originalRequest._retry = true
          const instance = createAxiosInstance()
          return instance.post('/rest/account/refresh', {
            refreshToken: btoa(refreshToken)
          }).then((response) => {
            if (response.status === 201) {
              localStorage.setItem('token', response.data.accessToken)
              localStorage.setItem('refreshToken', response.data.refreshToken)
              originalRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`
              return axios(originalRequest)
            }
          })
        } else {
          localStorage.removeitem('token')
          localStorage.removeitem('refreshToken')
          navigate('/login')
        }
      }

      return Promise.reject(error)
  })

  return instance
}

export default api()
