// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-markdown-page-layout-js": () => import("../src/layouts/MarkdownPageLayout.js" /* webpackChunkName: "component---src-layouts-markdown-page-layout-js" */),
  "component---src-pages-admin-js": () => import("../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-dashboard-js": () => import("../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

