import React from 'react'
import { navigate } from 'gatsby-link'
import styled from 'styled-components'
import { rem } from 'polished'
import { Menu, Button, PageHeader } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { DashboardFilled, HomeFilled } from '@ant-design/icons'
import 'antd/dist/antd.less'
import { usePage } from '../../contexts/PageProvider'

const NavContainer = styled.div`
  max-width: ${rem('256px')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`

const StyledButton = styled((props) => <Button {...props} />)`
  margin-bottom: ${rem('16px')};
  margin-left: ${rem('18px')};
  position: fixed;
  bottom: 0;
  left: 0;
`

const StyledMenu = styled(Menu)`
  height: 100vh;
  padding-top: ${rem('18px')};
`

const Container = styled.div`
  margin-left: ${rem('80px')};
  padding: ${rem('8px')};
`

const navTo = (path) => () => {
  return navigate(path)
}

const MainLayout = (props) => {
  console.log('props', props)
  const [collapsed, setCollapsed] = React.useState(true)
  const { title } = usePage()

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <>
      <NavContainer>
        <StyledMenu
          defaultSelectedKeys={['2']}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
        >
          <Menu.Item onClick={navTo('/')}>
            <HomeFilled />
            <span>Home</span>
          </Menu.Item>
          <Menu.Item onClick={navTo('/dashboard')}>
            <DashboardFilled />
            <span>Dashboard</span>
          </Menu.Item>
          <StyledButton type="primary" onClick={toggleCollapsed}>
            <FontAwesomeIcon icon={collapsed ? faAngleRight : faAngleLeft} />
          </StyledButton>
        </StyledMenu>
      </NavContainer>
      <Container>
        <PageHeader title={title} backIcon={false}>
          {props.children}
        </PageHeader>
      </Container>
    </>
  )
}

export default MainLayout
