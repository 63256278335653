import React from 'react'
import styled from 'styled-components'
import { useField, splitFormProps } from 'react-form'
import { default as MaterialTextField } from '@material-ui/core/TextField'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#101010' },
  },
  typography: {
    fontFamily: [ 'foco', 'sans-serif' ].join(','),
    fontWeight: 300,
    fontStyle: 'normal'
  }
});

const ErrorText = styled.span`
  color: #ac0000;
`

const TextField = React.forwardRef((props, ref) => {
  const [ field, fieldOptions, rest ] = splitFormProps(props)
  const { helperText, label, name, ...remainingProps } = rest

  const { meta, getInputProps } = useField(field, fieldOptions)

  const inputProps = getInputProps({ ref, ...remainingProps })

  return (
    <ThemeProvider theme={theme}>
      <MaterialTextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        inputProps={{ value: '', ...inputProps, name }}
        error={meta.error}
        helperText={meta.isTouched && meta.error ? (
          <ErrorText>
            {meta.error}
          </ErrorText>
        ) : helperText || ' '}
      />
    </ThemeProvider>
  )
})

export default TextField
