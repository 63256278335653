import React from 'react'
import styled from 'styled-components'
import { Descriptions, Tag, Typography } from 'antd'

const Container = styled.div`
  padding: ${props => props.theme.spacing.sm};
`

const DescriptionsContainer = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing.md};
`

const SpacedText = styled.span`
  margin-right: ${props => props.theme.spacing.sm};
`

const UserExpand = (props) => {
  let userType = props.user.type
  switch (userType) {
    case 'ADMIN':
      userType = 'Admin'
      break
    case 'MANAGER':
      userType = 'Manager'
      break
    case 'MEMBER':
      userType = 'Member'
      break
    default:
      userType = 'Invalid Type'
      break
  }

  const fullName = `${props.user.firstName} ${props.user.lastName}`

  return (
    <Container>
      <DescriptionsContainer>
        <Typography.Title level={4}>Account Information</Typography.Title>
        <Descriptions title={false} size="small">
          <Descriptions.Item label="Name">{fullName}</Descriptions.Item>
          <Descriptions.Item label="Type">{userType}</Descriptions.Item>
          <Descriptions.Item label="E-Mail Address">
            <SpacedText>{props.user.email}</SpacedText>
            <Tag color="red">Unverified</Tag>
          </Descriptions.Item>
        </Descriptions>
      </DescriptionsContainer>
    </Container>
  )
}

export default UserExpand
